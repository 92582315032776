export default {
  init() {
    $('.search-reveal a').on('click', function() {
      $('.search').removeClass('hide');
      $(this).addClass('hide');
    });

    $('.close-search').on('click', function() {
      $('.search').addClass('hide');
      $('.search-reveal a').removeClass('hide');
    });

    $('.navbar-toggler').on('click', function() {
      $(this).toggleClass('open');
    });

    $('#grid').on('click', function() {
      $('.showroll article').removeClass('list').addClass('grid');
      $(this).addClass('active');
      $('#list').removeClass('active');
    });

    $('#list').on('click', function() {
      $('.showroll article').removeClass('grid').addClass('list');
      $(this).addClass('active');
      $('#grid').removeClass('active');
    });

    $('.form-select').change(function() {
      var category = $(this).val();
      console.log(category);

      if (category === 'all') {
        $('.showroll .row article').fadeIn(450);
      } 
      else {
        $('.showroll .row article').fadeIn(450);
        $('.showroll .row article').not('.' + category).hide();
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
